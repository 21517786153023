import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white p-4 md:p-8 md:pb-12 relative z-10">
      <div className="container mx-auto flex justify-between items-center">
        <button onClick={toggleNavbar} className="md:hidden text-black text-3xl mr-4 z-20 relative">
          &#9776; {/* Hamburger Icon */}
        </button>
        <div className="flex-grow flex justify-center">
          <div className={`text-4xl md:text-5xl flex items-center text-black`} style={{ fontFamily: "'Great Vibes', cursive" }}>
            Mahdi & Deborah
          </div>
        </div>
        <div className={`fixed md:relative top-0 left-0 h-full w-full md:w-auto md:h-auto bg-white md:bg-transparent pt-4 md:pt-0 pb-4 md:pb-0 transition-transform duration-300 ease-in-out ${isOpen ? 'flex shadow-lg' : 'hidden'} md:flex flex-col justify-center items-center`} style={{ fontFamily: "'Libre Baskerville', serif" }}>
          <div className="flex flex-col md:flex-row md:items-center h-full md:h-auto">
            <Link className="text-black mx-2 md:mx-6 my-2 md:my-0 text-lg md:text-xl hover:underline" to="/home" onClick={closeNavbar}>Home</Link>
            <Link className="text-black mx-2 md:mx-6 my-2 md:my-0 text-lg md:text-xl hover:underline" to="/schedule_dress" onClick={closeNavbar}>Schedule & Dress Code</Link>
  
            <Link className="text-black mx-2 md:mx-6 my-2 md:my-0 text-lg md:text-xl hover:underline" to="/Maids" onClick={closeNavbar}>The Team</Link>
            <Link className="text-black mx-2 md:mx-6 my-2 md:my-0 text-lg md:text-xl hover:underline" to="/registry" onClick={closeNavbar}>Registry</Link>
            <Link className="text-black mx-2 md:mx-6 my-2 md:my-0 text-lg md:text-xl hover:underline" to="/reservation" onClick={closeNavbar}>Reservation</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
