import React, { useState, useEffect } from 'react';
import topImage1 from '../assets/Top Image1.jpg';
import topImage2 from '../assets/Top Image2.jpg';
import topImage3 from '../assets/Top image3.jpg';
import topImage4 from '../assets/Top Image4.jpg'; 
import topImage5 from '../assets/Top Image5.jpg';


import bottomImage from '../assets/BottomPic.jpg';

const images = [topImage1, topImage2, topImage3, topImage4, topImage5];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const countdownDate = new Date('2024-12-07T00:00:00').getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(countdownInterval);
        setTimeRemaining({});
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  return (
    <div>
      <div className="h-[100vh] sm:h-[110vh] md:h-[120vh] lg:h-[250vh] bg-gray-200 flex justify-center items-center overflow-hidden mb-12">
        <img
          src={images[currentImage]}
          alt={`Slide ${currentImage + 1}`}
          className="object-cover w-full h-full"
        />
      </div>

      <div className="mt-8 md:mt-12 mb-8 text-center">
        <h1 className="text-black text-4xl md:text-6xl lg:text-9xl font-serif" style={{ fontFamily: "'Great Vibes', cursive" }}>Mahdi</h1>
        <h2 className="text-black text-4xl md:text-6xl lg:text-9xl font-serif mt-2 md:mt-4" style={{ fontFamily: "'Great Vibes', cursive" }}>&</h2>
        <h1 className="text-black text-4xl md:text-6xl lg:text-9xl font-serif mt-2 md:mt-4 pb-2" style={{ fontFamily: "'Great Vibes', cursive" }}>Deborah</h1>
      </div>

      <div className="text-center mt-8 md:mt-18">
        <h3 className="text-3xl md:text-5xl lg:text-7xl font-serif pt-10 md:pt-20 pb-5 md:pb-10" style={{ fontFamily: "'Great Vibes', cursive" }}>7 December 2024</h3>
        <h2 className="text-base md:text-lg lg:text-xl font-serif pb-5 md:pb-10" style={{ fontFamily: "'Libre Baskerville', serif" }}>Johannesburg, South Africa</h2>

        {timeRemaining && (
          <div className="mt-2 md:mt-4">
            <h2 className="countdown text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-serif" style={{ fontFamily: "'Great Vibes', cursive" }}>
              {timeRemaining.days} Days {timeRemaining.hours} Hours {timeRemaining.minutes} Minutes {timeRemaining.seconds} Seconds
            </h2>
          </div>
        )}
      </div>

      <div className="text-center mt-4 md:mt-8">
        <div className="heart-beat-container">
          <div className="heart-beat">&#10084;</div>
        </div>
      </div>

      <div className="text-center mt-8 md:mt-12">
        <h1 className="text-4xl md:text-5xl lg:text-7xl font-serif pt-10 md:pt-20 pb-5 md:pb-10" style={{ fontFamily: "'Great Vibes', cursive" }}>Our Love Story</h1>
      </div>

      <div className="max-w-6xl mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="text-base md:text-lg lg:text-xl font-serif pr-4 border-r border-gray-300" style={{ fontFamily: "'Libre Baskerville', serif" }}>
          <p>
           Mahdi, a property manager in South Africa, hails from Asia, specifically Iran. His life was marked by a busy work schedule. Deborah, a language trainer, grew up in Lubumbashi, DRC, and later moved to South Africa for her studies. She too led a hectic life, balancing university, sports, and work. Their paths crossed in January 2022 when Deborah was conducting language sessions for Mahdi's mother. Initially maintaining a professional rapport, Mahdi eventually took a leap and invited her on a date.

          </p>
        </div>
        <div className="text-base md:text-lg lg:text-xl font-serif" style={{ fontFamily: "'Libre Baskerville', serif" }}>
          <p>
          After several unsuccessful attempts, Deborah finally agreed, and their first date took place on November 18th, marking the beginning of their love story. As they grew closer and spent more time together, they fell deeply in love, bridging their different races and cultures through their shared language of love. Their families met and embraced their relationship. Today, Mahdi and Deborah are on the path to marriage and living happily ever after.
          </p>
        </div>
      </div>

      <div className="text-center mt-8 mb-12">
        <img src={bottomImage} alt="Wedding Venue" className="mx-auto w-2/3 md:w-1/3 h-auto object-cover" />
      </div>

      <style jsx>{`
        .heart-beat-container {
          display: inline-block;
          width: 3rem;
          height: 3rem;
        }

        .heart-beat {
          color: red;
          font-size: 2rem;
          animation: beat 1s infinite;
        }

        @media (min-width: 768px) {
          .heart-beat {
            font-size: 3rem;
          }
        }

        @media (min-width: 1024px) {
          .heart-beat {
            font-size: 4rem;
          }
        }

        @keyframes beat {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
        }

        .countdown {
          font-size: 1.5rem; /* Default size */
          font-family: 'Great Vibes', cursive;
        }

        @media (min-width: 768px) {
          .countdown {
            font-size: 2.5rem; /* Medium size */
          }
        }

        @media (min-width: 1024px) {
          .countdown {
            font-size: 3.5rem; /* Large size */
          }
        }

        .border-r {
          border-right: 1px solid #ddd; /* Default border */
        }

        @media (max-width: 768px) {
          .border-r {
            border-right: none; /* Remove border on small screens */
          }
        }
      `}</style>
    </div>
  );
};

export default Home;
