import React from 'react';

import bridesmaid1 from '../assets/bridesmaid1.jpg';
import bridesmaid2 from '../assets/bridesmaid2.jpg';
import bridesmaid3 from '../assets/bridesmaid3.jpg';
import bridesmaid4 from '../assets/bridesmaid4.jpg';
import bridesmaid5 from '../assets/bridesmaid5.jpg';
import bridesmaid6 from '../assets/bridesmaid6.jpg';
import bridesmaid7 from '../assets/bridesmaid7.jpg';
import bridesmaid8 from '../assets/bridesmaid8.jpg';

const bridesmaids = [
  {
    name: 'Reza : Best man',
    image: bridesmaid1,
    description: 'I work in IT and I love camping. Mahdi is my childhood rival. He is younger than me but always one computer trick or a camping hack ahead of me! I may not be able to best him, but I shall be his best man!'
  },
  {
    name: 'Deborah : Maid of honor',
    image: bridesmaid2,
    description: 'Deborah is someone who embodies everything wonderful- she’s outgoing, fun-loving, and filled with endless positivity. I can’t wait to celebrate her love story and create more memories together.'
  },
  {
    name: 'Eli : Groomsman',
    image: bridesmaid4,
    description: 'I met Deborah at basketball at the University of Johannesburg. Being Congolese really brought great camaraderie, you just get the jokes differently. Debs would sing great tales about my brother Mahdi and what a sight to see them getting married! God bless them abundantly.'
  },
  {
    name: 'Naomie : Bridesmaid',
    image: bridesmaid3,
    description: 'I love making memories as life is all about memories. I met Deborah at church and we spent some good years at university together. We share good memories one of them being together in the same basketball team (the team I ran away from!)'
  },
  {
    name: 'Gabriel : Groomsman',
    image: bridesmaid5,
    description: 'I’m a French-Italian journalist who’s extremely curious and loves learning new things. I write and paint! I met Mahdi years ago through common friends and was immediately captivated by his talent in photography. I met Deborah later and was amazed by her wonderful personality.'
  },
  {
    name: 'Esther : Bridesmaid',
    image: bridesmaid6,
    description: 'I love watching TV and I’m very passionate about singing. I met Deborah at church, she’s a lovely person with a unique personality which led her to find her perfect match. Can’t wait to celebrate you guys on your special day!'
  },
  {
    name: 'David : Groomsman',
    image: bridesmaid7,
    description: 'I’m Deborah’s friend and cousin. I met Mahdi in 2022 at Deborah’s birthday party and let me tell you he’s an amazing person. I’m super excited about the wedding, see y’all there!'
  },
  {
    name: 'Joséphine : Bridesmaid',
    image: bridesmaid8,
    description: 'Since I arrived in South Africa, Mahdi and Deborah have been the most welcoming, their class only equals their party vibe and talent on the basketball court!'
  }
];

const Maids = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl  mb-8 text-center" style={{ fontFamily: "'Great Vibes', cursive" }}>Our Groomsmen and Bridesmaids</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {bridesmaids.map((bridesmaid, index) => (
          <div key={index} className="flex flex-col items-center bg-white p-4 rounded-lg shadow-md">
            <div className="w-full h-[32rem] overflow-hidden rounded-t-lg flex-shrink-0">
              <img src={bridesmaid.image} alt={bridesmaid.name} className="w-full h-full object-cover" />
            </div>
            <div className="p-4 text-center">
              <h2 className="text-2xl font-semibold mb-2 italic">{bridesmaid.name}</h2>
              <p className="text-base md:text-lg lg:text-xl" style={{ fontFamily: "'Times New Roman', serif" }}>{bridesmaid.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Maids;
