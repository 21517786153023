import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white-800 text-gray text-center py-6">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold mb-2">Laby</h1>
        <p className="mb-4">Start your wedding website, emmanuellaby@gmail.com, +27(68) 388-9673 </p>
        <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
          
          
         
         
        </div>
        <div className="mb-4">
          
        </div>
        <div className="text-sm">
          <p>&copy; 2024 Laby, Inc. All rights reserved.</p>
         
        </div>
      </div>
    </footer>
  );
};

export default Footer;
