import React from 'react';
import dressCodeImage from '../assets/Dress code.jpg'; // Updated to the new image

const ScheduleDress = () => {
  const addToCalendar = () => {
    const icsFile = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//hacksw/handcal//NONSGML v1.0//EN
BEGIN:VEVENT
UID:uid1@example.com
DTSTAMP:20230615T093000Z
ORGANIZER;CN=Mahdi Deborah:Deborah: smh1988@gmail.com
DTSTART:20241207T130000Z
DTEND:20241207T220000Z
SUMMARY:Wedding
DESCRIPTION:Join us for the wedding of Mahdi and Deborah
LOCATION:72 R563, Protea Ridge, Krugersdorp, 1739
END:VEVENT
END:VCALENDAR
    `.trim();

    const blob = new Blob([icsFile], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'event.ics';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container mx-auto mt-4 text-center px-4" style={{ fontFamily: "'Libre Baskerville', serif" }}>
      <h1 className="text-3xl md:text-4xl  mb-4" style={{ fontFamily: "'Great Vibes', cursive" }}>Dress Code</h1>
      <p className="text-base md:text-lg mt-4">Black-tie</p>
      <div className="flex justify-center">
        <img src={dressCodeImage} alt="Dress Code" className="w-full md:w-1/3 lg:w-1/4 h-auto object-cover" />
      </div>
      <p className="text-lg mt-4">Our theme color is blue with a touch of gold</p>

      <div className="mt-8">
        <h2 className="text-2xl md:text-3xl" style={{ fontFamily: "'Great Vibes', cursive" }}>Saturday, 07 December 2024</h2>
        <h3 className="text-xl md:text-2xl mt-4" style={{ fontFamily: "'Great Vibes', cursive" }}>Monte Vista Venue</h3>
        <p className="text-base md:text-lg mt-4">
          Address: 72 R563, Protea Ridge, Krugersdorp, 1739
        </p>
        <p className="text-base md:text-lg mt-4">
          We request that you be on time for the event to go as planned!
        </p>

        <div className="mt-8">
          <h2 className="text-2xl md:text-2xl mb-4" style={{ fontFamily: "'Great Vibes', cursive" }}>Schedule</h2>
          <p className="text-base md:text-lg">
            <span>Photoshoot and Cocktail:</span> 16:00 - 18:00<br />
            <span>Reception:</span> 18:00 - 22:00<br />
          </p>
        </div>

        <div className="mt-8">
          <button
            className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-400"
            onClick={addToCalendar}
          >
            Add to Calendar
          </button>
        </div>

        <div className="mt-8 mb-12 flex justify-center">
          <iframe
            title="Wedding Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.1618887333047!2d27.794987715090116!3d-26.055703683497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95b53b15c2e5ff%3A0x95c02d69b99246ff!2s72%20R563%2C%20Protea%20Ridge%2C%20Krugersdorp%2C%201739!5e0!3m2!1sen!2sza!4v1622729827369!5m2!1sen!2sza"
            width="400"
            height="400"
            style={{ border: 0, borderRadius: '10px' }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDress;
