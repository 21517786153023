import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ScheduleDress from './pages/ScheduleDress';
import Reservation from './pages/Reservation';
import Registry from './pages/Registry';
import Footer from './components/Footer';
import Maids from './pages/Maids';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <header>
          <Navbar />
        </header>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} /> {/* Default route */}
            <Route path="/home" element={<Home />} />
            <Route path="/schedule_dress" element={<ScheduleDress />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/registry" element={<Registry />} />
            <Route path="/maids" element={<Maids />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
