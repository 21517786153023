import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import RegistryImage from '../assets/Registry.jpg'; // Make sure this path is correct

const Reservation = () => {
  const [formData, setFormData] = useState({ name: '', surname: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      surname: formData.surname,
      date: '2022-12-15', // Replace with desired date
    };

    emailjs.send('service_rc63jah', 'template_yav0m98', templateParams, 'IuApIeJoDKxFHwZFW')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccessMessage(`Reservation successful for ${formData.name} ${formData.surname}`);
        setIsSubmitted(true);
        setFormData({ name: '', surname: '' });
      }, (error) => {
        console.error('FAILED...', error);
      });

    // Clear form fields
    setFormData({ name: '', surname: '' });
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div className="container mx-auto mt-4 flex flex-col items-center min-h-screen mb-20">
      <h1 className="text-4xl  mb-8 text-center" style={{ fontFamily: "'Great Vibes', cursive" }}>Reservation</h1>
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg relative">
        <div className="relative mb-4">
          <img src={RegistryImage} alt="Registry" className="w-full h-64 object-cover rounded-lg" />
        </div>
        {successMessage && <p className="text-green-300 text-center mb-4 font-bold">{successMessage}</p>}
        {!isSubmitted ? (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-800">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-800">Surname</label>
              <input
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <h3 className="mb-4">Please make your reservation before the 30th of November.</h3>
            <button type="submit" className="w-full py-2 px-4 bg-gray-800 text-white rounded hover:bg-gray-600">Confirm Reservation</button>
          </form>
        ) : (
          <p className="text-center text-gray-800">Thank you! Your reservation has been confirmed.</p>
        )}
      </div>
    </div>
  );
};

export default Reservation;
