import React, { useState } from 'react';
import bedCoverImage from '../assets/Bed Cover.jpg';
import kitchenSetImage from '../assets/Kitchen Set.jpg';
import carpetImage from '../assets/Carpet.jpg';
import tripImage from '../assets/Trip.jpg';
import tvImage from '../assets/Tv.jpg';
import couchImage from '../assets/Couch.jpg';
import diningSetImage from '../assets/Dining table and chairs.jpg';
import dishSetImage from '../assets/Dish set.jpg';
import ironSetImage from '../assets/Iron set.jpg';
import mirrorImage from '../assets/Mirror.jpg';
import drawersImage from '../assets/Drawers.jpg';
import glassContainersImage from '../assets/Glass containers.jpg';
import kettleImage from '../assets/Kettle.jpg';
import teaSetImage from '../assets/Tea set.jpg';
import toasterImage from '../assets/Toaster.jpg';
import vacuumCleanerImage from '../assets/Vaccum cleaner.jpg';
import vaseSetImage from '../assets/Vase set.jpg';
import doormatImage from '../assets/Doormat.jpg';
import cutleryImage from '../assets/Cutlery.jpg';
import knivesImage from '../assets/Knives.jpg';
import washingMachineImage from '../assets/Washing Machine.jpg';
import honeymoonImage from '../assets/Honey Moon.jpg';
import carContributionImage from '../assets/Car Contribution.jpg';
import weddinggiftcardImage from '../assets/Wedding Gift Card.jpg';
import glassesImage from '../assets/Glasses.jpg';
import microwaveImage from '../assets/Microwave.jpg';
import curtainImage from '../assets/Curtains.jpg'; // New curtain image

const bankDetails = `Bank: FNB
Account Number: 62939434388
Account Holder: SeyedMahdi
Branch Code: 250655
PayPal: smh1988@gmail.com`;

const items = [
  { id: 1, image: honeymoonImage, title: 'Honeymoon', price: 'Amount of your choice', bankDetails: `${bankDetails}\nReference: Gifter'name & Honeymoon` },
  { id: 2, image: tripImage, title: 'Trip', price: 'Amout of your choice', bankDetails: `${bankDetails}\nReference:  Gifter'name & Trip` },
  { id: 3, image: carContributionImage, title: 'Car Contribution', price: 'Amount of your choice', bankDetails: `${bankDetails}\nReference: Gifter'name & Car Contribution` },
  { id: 4, image: couchImage, title: 'Couch', price: 'R10,000', bankDetails: `${bankDetails}\nReference: Gifter'name & Couch` },
  { id: 5, image: tvImage, title: 'TV', price: 'R7000', bankDetails: `${bankDetails}\nReference: Gifter'name & TV` },
  { id: 6, image: carpetImage, title: 'Carpet', price: 'R1500', bankDetails: `${bankDetails}\nReference: Gifter'name & Carpet` },
  { id: 7, image: washingMachineImage, title: 'Washing Machine', price: 'R5500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Washing Machine` },
  { id: 8, image: ironSetImage, title: 'Iron Set', price: 'R1100', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Iron Set` },
  { id: 9, image: drawersImage, title: ' Dresser', price: 'R4000', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Drawers` },
  { id: 10, image: bedCoverImage, title: ' Duvet', price: 'R1500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Bed Cover` },
  { id: 11, image: kitchenSetImage, title: 'Pot Set', price: 'R3000', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Kitchen Set` },
  { id: 12, image: diningSetImage, title: 'Dining Table and Chairs', price: 'R7000', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Dining Table and Chairs` },
  { id: 13, image: dishSetImage, title: 'Dish Set', price: '800', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Dish Set` },
  { id: 14, image: mirrorImage, title: 'Mirror', price: 'R500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Mirror` },
  { id: 15, image: glassContainersImage, title: 'Glass Containers', price: 'R350', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Glass Containers` },
  { id: 16, image: kettleImage, title: 'Kettle', price: 'R500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Kettle` },
  { id: 17, image: teaSetImage, title: 'Tea Set', price: 'R800', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Tea Set` },
  { id: 18, image: toasterImage, title: 'Toaster', price: 'R500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Toaster` },
  { id: 19, image: vacuumCleanerImage, title: 'Vacuum Cleaner', price: 'R2000', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Vacuum Cleaner` },
  { id: 20, image: vaseSetImage, title: 'Vase Set', price: 'R350', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Vase Set` },
  { id: 21, image: doormatImage, title: 'Doormat', price: 'R250', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Doormat` },
  { id: 22, image: cutleryImage, title: 'Cutlery Set', price: '1000', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Cutlery` },
  { id: 23, image: knivesImage, title: 'Knives Set', price: 'R500', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Knives Set` },
  { id: 25, image: glassesImage, title: 'Glasses Set', price: 'R600', bankDetails: `${bankDetails}\nReference: Gifter'name & Glasses Set` },
  { id: 26, image: microwaveImage, title: 'Microwave', price: 'R3500', bankDetails: `${bankDetails}\nReference: Gifter'name & Microwave` },
  { id: 27, image: curtainImage, title: 'Curtain', price: 'R2000', bankDetails: `${bankDetails}\nReference: Gifter'name & Curtain` },
  { id: 24, image: weddinggiftcardImage, title: 'Wedding Gift Card', price: 'R200', bankDetails: `${bankDetails}\nReference:  Gifter'name &  Wedding Gift Card` } // New curtain item
];

const Registry = () => {
  const [flippedItems, setFlippedItems] = useState([]);

  const toggleFlip = (id) => {
    setFlippedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <div className="container mx-auto mt-4 px-4">
      <h1 className="text-4xl  mb-8 text-center" style={{ fontFamily: "'Great Vibes', cursive" }}>Registry</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {items.map((item) => (
          <div key={item.id} className="relative w-full h-112 group">
            <div className={`relative w-full h-full transition-transform duration-700 transform-style-3d ${flippedItems.includes(item.id) ? 'rotate-y-180' : ''}`}>
              <div className="absolute w-full h-full backface-hidden p-4 bg-white rounded-lg shadow-lg">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-3/4 object-cover rounded-t-lg"
                />
                <div className="p-2">
                  <h2 className="text-xl font-bold">{item.title}</h2>
                  <p className="text-gray-800">{item.price}</p>
                  <button
                    className="mt-2 py-2 px-4 bg-gray-800 text-white rounded hover:bg-gray-600"
                    onClick={() => toggleFlip(item.id)}
                  >
                    Show Bank Details
                  </button>
                </div>
              </div>
              <div className="absolute w-full h-full backface-hidden p-4 bg-white rounded-lg shadow-lg transform rotate-y-180">
                <div className="flex items-center justify-center h-full p-4">
                  <p className="text-gray-800 text-center whitespace-pre-wrap">{item.bankDetails}</p>
                </div>
                <button
                  className="absolute bottom-4 left-1/2 transform -translate-x-1/2 mt-2 py-2 px-4 bg-gray-600 text-white rounded hover:bg-gray-500"
                  onClick={() => toggleFlip(item.id)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style>{`
        .transform-style-3d {
          transform-style: preserve-3d;
        }
        
        .backface-hidden {
          backface-visibility: hidden;
        }
        
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
        
        .h-112 {
          height: 28rem; /* Custom height for the grid items */
        }
      `}</style>
    </div>
  );
};

export default Registry;
